/* mobile styles */
@media (max-width: 600px) {
    body {
        font-size: 14px;
        padding: 10px;
    }

    h1, h2, h3 {
        font-size: 1.5rem;
    }
}

