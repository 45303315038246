.contact-container {
    padding: 20px;
    background-color: #2c3e50;
    border-radius: 8px;
    color: #ffffff;
    max-width: 600px;
    margin: auto;
}

.contact-container h2{
    text-align: center;
    margin: 20px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
}

.contact-form input {
    background-color: #34495e;
    color: #ffffff;
}

.contact-form textarea {
    background-color: #34495e;
    color: #ffffff;
    resize: none;
}

.contact-form button {
    background-color: #e74c3c;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin: 30px;
}

.contact-form button:hover {
    background-color: #c0392b;
}
