.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
}

.hero {
  text-align: center;
  padding: 50px 0;
}

.footer {
  text-align: center;
  padding: 20px;
  background-color: #282c34;
  color: white;
}
