.project-card {
    background-color: #2c3e50;
    border-radius: 8px;
    border: none;
    padding: 20px;
    margin: 20px;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.project-card:hover {
    transform: translateY(-10px);
}

.project-card h3 {
    color: #ffffff;
    margin-bottom: 15px;
}

.project-card p {
    color: #ecf0f1;
    font-size: 14px;
    line-height: 1.6;
}

.github-link {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #ffffff;
    text-decoration: none;
}

.github-link:hover {
    color: #e74c3c;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 600px) {
    .projects-grid {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    .projects-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
