.nav-link {
    position: relative;
    text-decoration: none;
    color: white;
}

.navbar ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    margin: 0;
    position: relative;
    text-decoration: none;
    color: white;
}

.menu-icon {
    display: none;
    cursor: pointer;
    color: #ffffff;
}

.nav-link::after {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    background: #e74c3c;
    transition: width 0.3s;
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
}

.nav-link:hover::after,
.nav-link:focus::after,
.nav-link.active::after {
    width: 100%;
}
/* Header for mobile */
@media (max-width: 600px) {
    .menu-icon {
        display: block;
    }

    .navbar ul {
        display: none;
        flex-direction: column;
        position: absolute;
        padding: 15px;
        top: 40px;
        left: 0;
        right: 0;
        background-color: #2c3e50;
        z-index: 10;
        width: 130px;
    }

    .navbar ul.active {
        display: flex;
    }
}
