/* Main Profile Section */
.profile-section {
    margin: 0 50px;
    padding: 30px;
    background-color: #2c3e50;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.profile-img {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    margin-bottom: 20px;
}

.profile-text {
    flex: 1;
    margin-left: 20px;
    color: #ffffff;
}

.profile-text h3 {
    margin-bottom: 5px;
    color: #e74c3c;
}

.profile-text p {
    margin: 0;
}

.profile-text .username {
    font-style: italic;
    color: #a3a3a3;
}

.skills-languages-section {
    height: 100%;
    margin: 20px 50px;
}

.skills-languages-section .section-content {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.skills-languages-section .section-item {
    flex: 1;
    height: 47vh;
}

.about-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

@media (max-width: 768px) {
    .profile-section {
        margin: 0 20px;
        flex-direction: column;
        align-items: center;
    }

    .profile-img {
        margin-right: 0;
        margin-bottom: 20px;
        width: 120px;
        height: 120px;
    }

    .profile-text {
        margin-left: 0;
        text-align: center;
    }

    .skills-languages-section {
        margin: 20px;
    }

    .skills-languages-section .section-content {
        flex-direction: column;
    }

    .skills-languages-section .section-item {
        flex: 1;
        height: auto;
    }

    .about-grid {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    .profile-section {
        margin: 0 30px;
    }

    .skills-languages-section .section-content {
        gap: 15px;
    }

    .about-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}
