.hero {
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5rem;
}

.avatar-container {
    position: relative;
    padding: 5px;
    margin-bottom: 1.5rem;
}

.ring {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #dd5749, #af382b, #c58c86, #7e7d7d);
    background-size: 400% 400%;
    animation: changeColor 8s ease infinite;
}

.avatar {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
}

.display-4 {
    font-family: 'Lobster', cursive;
    font-size: 4rem;
}

.lead {
    font-size: 1.25rem;
}

@keyframes changeColor {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@media (max-width: 600px) {
    h2 {
        font-size: 2.5rem;
    }

    p.lead {
        font-size: 1rem;
    }
}
